<template>
  <div>
    <vs-card>
      <div v-if="errors.count() > 0">
        <fieldset class="fieldErrors">
          <legend>Atenção aos erros do formulário:</legend>
          <ul>
            <li
              v-for="(error, index) in errors.all()"
              :key="index"
              class="alert"
            >
              {{ error }}
            </li>
          </ul>
        </fieldset>
      </div>
      <form>
        <p>* Nome da peça</p>
        <vs-input
          v-validate="'required'"
          name="name"
          v-model="request.name"
          class="w-full mb-6"
          data-vv-as="Nome da Peça"
        />
        <ul class="con-s">
          <li>
            <label for="hasSAPCode">* Possui código SAP</label>
            <vs-switch v-model="request.hasSAPCode" id="hasSAPCode" />
          </li>
        </ul>
        <p v-if="request.hasSAPCode">* Informar Código SAP</p>
        <vs-input
          v-validate="'required|digits:8'"
          name="sapCode"
          v-mask="'########'"
          v-model="request.sapCode"
          v-if="request.hasSAPCode"
          class="w-full mb-6"
          data-vv-as="Código SAP"
        />
        <p v-if="request.hasSAPCode">
          * Valor SAP Unitário
          <money
            v-validate="'required'"
            name="sapValue"
            v-if="request.hasSAPCode"
            v-model="request.sapValue"
            class="w-full mb-6 select1"
            data-vv-as="Valor SAP"
          ></money>
        </p>
        <p v-if="!request.hasSAPCode">
          Valor estimado (Valor indicado no orçamento do fornecedor)
          <money
            name="estimatedValue"
            v-if="!request.hasSAPCode"
            v-model="request.estimatedValue"
            class="w-full mb-6 select1"
            data-vv-as="Valor estimado"
          ></money>
        </p>
        <p>
          * Família
          <select
            name="family"
            v-validate="'required'"
            v-model="request.familyId"
            label="Família"
            class="w-full mb-6 select1"
            @change="handleSelectedItem()"
            data-vv-as="Família"
          >
            <option value="-1">Cadastrar nova (+)</option>
            <option
              :key="index"
              :value="item.id"
              v-for="(item, index) in familyOptions"
            >
              {{ item.name }}
            </option>
          </select>
        </p>
        * Classificação
        <select
          v-validate="'required'"
          name="classification"
          v-model="request.classificationId"
          label="Classificação"
          class="w-full mb-6 select1"
          data-vv-as="Classificação"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in classificationOptions"
          >
            {{ item.name }}
          </option>
        </select>
        <div>
          <p>Justificativa da demanda</p>
          <vs-input
            v-validate="'required'"
            name="justification"
            v-model="request.justification"
            class="w-full mb-6"
            data-vv-as="Justificativa"
          ></vs-input>
        </div>
        <!-- * Aplicação
        <select
          v-validate="'required'"
          name="application"
          v-model="request.applicationId"
          label="Aplicação"
          class="w-full mb-6 select1"
          data-vv-as="Aplicação"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in applicationOptions"
          >
            {{ item.name }}
          </option>
        </select>-->

        * Impacto
        <select
          v-validate="'required'"
          name="impact"
          v-model="request.impactId"
          label="Área"
          class="w-full mb-6 select1"
          data-vv-as="Impacto"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in impactOptions"
          >
            {{ item.name }}
          </option>
        </select>
        Área
        <vs-select
          autocomplete
          @change="loadSubareas()"
          class="w-full mb-6"
          v-model="request.areaId"
          name="area"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in areaOptions"
          />
        </vs-select>

        Subárea
        <vs-select
          name="subarea"
          autocomplete
          @change="loadEquipments()"
          class="w-full mb-6"
          v-model="request.subareaId"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in subareaOptions"
          />
        </vs-select>

        Equipamento
        <vs-select
          autocomplete
          class="w-full mb-6"
          v-model="request.equipmentId"
          data-vv-as="Equipamento"
          name="equipment"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in equipmentOptions"
          />
        </vs-select>

        <p>* Descrição Técnica</p>
        <vs-textarea
          v-validate="'required'"
          name="description"
          v-model="request.description"
          class="w-full mb-6"
          data-vv-as="Descrição"
        ></vs-textarea>

        <p>* Imagem do projeto</p>
        <input
          class="selectNew"
          ref="image"
          type="file"
          name="image"
          @change="onInput"
          v-validate="'required|image'"
          data-vv-as="Imagem do projeto"
        />

        <p v-if="isAdmin" style="margin-top: 1em">Unidade Solicitante</p>
        <vs-select
          name="requestUnitId"
          autocomplete
          class="w-full mb-6"
          v-model="request.requestUnitId"
          v-if="isAdmin"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in unitOptions"
          />
        </vs-select>

        <vs-divider></vs-divider>
        <p><small>* campos obrigatórios</small></p>
        <p>
          <vs-button @click="submitProject()" class="mr-6">Cadastrar</vs-button>
        </p>
      </form>
    </vs-card>
    <vs-popup
      title="Cadastrar nova família"
      :active.sync="showFamilyAddScreen"
      v-if="showFamilyAddScreen"
    >
      <form>
        <p>Nome da família</p>
        <vs-input
          name="newFamilyName"
          v-model="family.name"
          class="w-full mb-6"
          @keyup.enter="submitFamily()"
          data-vv-as="Nome da Família"
        />

        <vs-button @click="submitFamily()" class="mr-6">Cadastrar</vs-button>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import siriusAPI from "../services";
import { notifyError, notifySuccess } from "../utils/helpers";

export default {
  data() {
    return {
      request: {
        name: "",
        familyId: 0,
        sapValue: null,
        estimatedValue: null,
        sapCode: "",
        necessityAt: null,
        classificationId: 10,
        applicationId: 0,
        equipmentId: null,
        description: "",
        kpi: 0,
        qtd: 1,
        justification: "",
        impactId: 0,
        areaId: null,
        subareaId: null,
        requestUnitId: 0,
      },
      family: { name: "" },
      familyOptions: [],
      unitOptions: [],
      classificationOptions: [],
      applicationOptions: [],
      areaOptions: [],
      impactOptions: [],
      equipmentOptions: [],
      subareaOptions: [],
      showFamilyAddScreen: false,
      files: null,
      isAdmin: false,
    };
  },
  created() {
    this.loadClassifications();
    //this.loadApplications();
    this.loadAreas();
    this.loadImpacts();
    this.loadUnits();
    this.loadFamilies();
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = loggedUser.profile.name;
    if (userProfile == "Administrador") {
      this.isAdmin = true;
      this.request.requestUnitId = loggedUser.unit.id;
    }
  },

  methods: {
    loadUnits() {
      new siriusAPI()
        .getUnits()
        .then((res) => {
          this.unitOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de unidades " + error
          );
        });
    },
    resetRequest() {
      let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
      this.request = {
        name: "",
        familyId: 0,
        sapValue: null,
        estimatedValue: null,
        sapCode: "",
        necessityAt: null,
        classificationId: 10,
        //applicationId: 0,
        equipmentId: null,
        description: "",
        kpi: 0,
        qtd: 1,
        justification: "",
        impactId: 0,
        areaId: null,
        requestUnitId: loggedUser.unit.id,
      };
    },
    async onInput(event) {
      this.files = event.target.files[0];
    },

    async loadFamilies() {
      new siriusAPI()
        .getFamilies()
        .then((res) => {
          this.familyOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de famílias " + error
          );
        });
    },
    submitProject() {
      this.$validator.validate().then((valid) => {
        if (!valid) {
          notifyError(this, "Corrigir os erros apresentados.");
          return;
        } else {
          if (!this.files) {
            notifyError(this, "Selecione a imagem do projeto!");
          } else {
            this.$vs.loading();
            const formData = new FormData();
            formData.append("image", this.files, this.files.name);

            new siriusAPI()
              .addRequest(this.request)
              .then((res) => {
                new siriusAPI()
                  .addProjectImage(res.data.project.uuid, formData)
                  .then(() => {
                    notifySuccess(
                      this,
                      "Novo desenvolvimento gerado com sucesso!"
                    );
                    this.resetRequest();
                    this.$vs.loading.close();
                  })
                  .catch((error) => {
                    notifyError(
                      this,
                      "Erro ao tentar inserir a imagem: " + error
                    );
                    this.$vs.loading.close();
                  });
              })
              .catch((error) => {
                notifyError(
                  this,
                  "Erro ao tentar gerar um novo desenvolvimento " + error
                );
                this.$vs.loading.close();
              });
          }
        }
      });
    },
    handleSelectedItem() {
      if (this.request.familyId == "-1") this.showFamilyAddScreen = true;
    },

    async submitFamily() {
      if (this.family.name.length == 0) {
        notifyError(this, "Preencha o nome da família");
        return;
      }
      const family = { name: this.family.name.toUpperCase() };
      this.$vs.loading();
      await new siriusAPI()
        .addFamily(family)
        .then((res) => {
          this.$vs.loading.close();
          this.request.family = res.data.id;
          notifySuccess(this, "Família cadastrada com sucesso");
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar cadastrar nova família: " + error);
        });
      await this.loadFamilies();
      this.showFamilyAddScreen = false;
    },

    loadEquipments() {
      if (this.request.subareaId) {
        const subareaId = this.request.subareaId;
        this.request.equipmentId = null;
        this.$vs.loading();
        new siriusAPI()
          .getEquipmentsByArea(subareaId)
          .then((res) => {
            this.equipmentOptions = res.data;
            if (this.equipmentOptions[0] == undefined)
              notifyError(
                this,
                "Não há equipamentos vinculados a área selecionada."
              );
            this.$vs.loading.close();
          })
          .catch((error) => {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de equipamento: " + error
            );
            this.$vs.loading.close();
          });
      }
    },

    loadSubareas() {
      const areaId = this.request.areaId;
      this.equipmentOptions = [];
      this.request.equipmentId = null;
      this.request.subareaId = null;
      this.$vs.loading();
      new siriusAPI()
        .getSubAreas(areaId)
        .then((res) => {
          this.subareaOptions = res.data;
          if (this.subareaOptions[0] == undefined)
            notifyError(this, "Não há subáreas vinculadas a área selecionada.");
          this.$vs.loading.close();
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de subárea: " + error
          );
          this.$vs.loading.close();
        });
    },

    loadClassifications() {
      new siriusAPI()
        .getClassifications()
        .then((res) => {
          this.classificationOptions = res.data;
          this.request.classificationId = this.classificationOptions[0].id;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de classificação: " + error
          );
        });
    },

    loadImpacts() {
      new siriusAPI()
        .getImpacts()
        .then((res) => {
          this.impactOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de impacto: " + error
          );
        });
    },

    loadApplications() {
      new siriusAPI()
        .getApplications()
        .then((res) => {
          this.applicationOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de aplicação: " + error
          );
        });
    },

    loadAreas() {
      new siriusAPI()
        .getAreas()
        .then((res) => {
          this.areaOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de área: " + error
          );
        });
    },
  },
};
</script>
<style scoped>
.radio-space {
  padding-right: 0.5em;
}
ul {
  margin-bottom: 0.8em;
}
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.alert {
  color: #f00;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
</style>
